
import { defineComponent, ref } from 'vue'
import { projectFirestore } from '@/firebase/config'
import getUser from '@/composables/getUser'

export default defineComponent({
  setup () {
    const global = window
    const loginCode = ref<string>('')
    const error = ref(false)

    // check if user is lgged in for administration
    const { user } = getUser()

    const submitLogin = async () => {
      // ymDAKSzkNSZYedYpREvP
      console.log('code', loginCode.value)
      try {
        const collectionRef = await projectFirestore.collection('games').doc(loginCode.value).get()
        console.log('collectionRef', collectionRef.data()?.project.id)
        if (collectionRef.data()) {
          global.location.href = 'https://' + collectionRef.data()?.project.id + '.escape-if-you-can.online/' + loginCode.value + '/'
        } else {
          throw new Error()
        }
      } catch (err) {
        error.value = true
      }
    }

    return { submitLogin, loginCode, error }
  }
})
