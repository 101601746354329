import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'

const firebaseConfig = {
  apiKey: 'AIzaSyD6pl3SUTP-xHCA3YKJlSVLQZvwrGzN9jc',
  authDomain: 'escape-if-you-can-01.firebaseapp.com',
  projectId: 'escape-if-you-can-01',
  storageBucket: 'escape-if-you-can-01.appspot.com',
  messagingSenderId: '1056918206889',
  appId: '1:1056918206889:web:99d103db8129ce8d8fecb8'
}

// init firebase
firebase.initializeApp(firebaseConfig)

// init authentification
const projectAuth = firebase.auth()

// init firestore service
const projectFirestore = firebase.firestore()

// timestamp
const timestamp = firebase.firestore.FieldValue.serverTimestamp()

// export firestore
export { projectAuth, projectFirestore, timestamp }
